// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons_TR0VX{border-bottom:none;border-top-left-radius:.5rem;border-top-right-radius:.5rem;display:flex;gap:.5rem;padding:.5rem}.editor-list{padding:0 1rem!important}.editor-paragraph{background-color:inherit;min-height:1rem}.tiptap{border-bottom-left-radius:.5rem;border-bottom-right-radius:.5rem;color:var(--color-primary);min-height:24rem;padding:1rem}.ProseMirror-focused{outline:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "buttons_TR0VX"
};
module.exports = ___CSS_LOADER_EXPORT___;
