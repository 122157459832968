// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_eZJbc,.content_O6bKz,.left_1t5q8,.menu_IPJXB,.right_FoQPU{display:flex}.container_eZJbc{flex-direction:row;height:100%}.left_1t5q8{background-color:var(--color-accent-100);width:16rem}.left_1t5q8,.menu_IPJXB{flex-direction:column;height:100%}.menu_IPJXB{border-right:1px solid var(--color-accent-200);gap:.5rem;padding:1rem}.right_FoQPU{margin-left:var(--layout-results-split-left-width);width:calc(100% - 16rem)}.content_O6bKz,.right_FoQPU{flex-direction:column}.content_O6bKz{background-color:var(--color-white);height:100%}.overflow_Ml2ug{overflow:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_eZJbc",
	"content": "content_O6bKz",
	"left": "left_1t5q8",
	"menu": "menu_IPJXB",
	"right": "right_FoQPU",
	"overflow": "overflow_Ml2ug"
};
module.exports = ___CSS_LOADER_EXPORT___;
